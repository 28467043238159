import React from "react";
import "./App.scss";
import {
  sectionContent,
  imgArray,
  pointsForToken,
  valueSection,
  emplList,
} from "./data";
import SectionBox from "./components/SectionBox/SectionBox";
import EmplCard from "./components/EmplCard/EmplCard";
import { Link } from "react-scroll";
import Navbar from "./components/NavBar/Navbar";

const headerCover = (img) => ({
  background: `url(${img})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

function App() {
  return (
    <div className="App">
      <Navbar />
      <header
        className="filter blur ton"
        style={headerCover(imgArray[0])}
        id="section0"
      >
        <section className="cmpny-title cnt-lgmnt">
          {sectionContent[0].title}
        </section>

        <section className="hdr-content cnt-lgmnt">
          <div className="hdr-desc-content">
            {sectionContent[0].textContent}
          </div>
          <Link
            to="section1"
            spy={true}
            smooth={true}
            duration={1000}
            activeClass="active"
            offset={-100}
          >
            <div className="order-btn">
              <p>learn more</p>
            </div>
          </Link>
        </section>
      </header>

      <div className="Container" id="section1">
        <div className="aboutSection">
          <div
            className="img-section img-cont-aligm pre-header"
            style={{ backgroundImage: `url(${imgArray[3]})` }}
          ></div>
          <div className="about-textContent">
            <div className="about-title type1 title-text">
              {sectionContent[1].title}
            </div>
            <div className="about-text type1 cnt-text">
              {sectionContent[1].textContent}
            </div>
          </div>
        </div>
        <ul className="overview-list">
          {sectionContent.slice(2, 5).map((section, index) => (
            <li className="overview-item" key={index}>
              <SectionBox title={section.title} text={section.textContent} />
            </li>
          ))}
        </ul>

        <div className="stripe-list">
          <div className="stripe-title">
            <p>our values</p>
          </div>
          <ul className="array-list">
            {valueSection.map((item, index) => (
              <React.Fragment key={index}>
                <li className="value-item">
                  <div className="item-icon img-cont-aligm" 
                  style={{ backgroundImage: `url(${item.icon})` }}></div>
                  <div className="item-title">{item.name}</div>
                </li>
                {index < valueSection.length - 1 && (
                  <li className="array-separator" />
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>

      <div className="Container" id="section2">
        <div className="aboutSection">
          <div className="about-textContent">
            <div className="about-title type2 title-text">
              {sectionContent[5].title}
            </div>
            <div className="about-subtitle type2 sub-title">
              {sectionContent[5].sub_title}
            </div>
            <div className="about-text type2 cnt-text">
              {sectionContent[5].textContent}
            </div>
            <div className="btn-container">
              <a href="https://funooni.io">
                <div className="redirect-btn">
                  <p>learn more</p>
                </div>
              </a>
            </div>
          </div>
          <div
            className="img-section img-cont-aligm"
            style={{ backgroundImage: `url(${imgArray[4]})` }}
          ></div>
        </div>

        <div className="sub-content">
          <div className="title-text">{sectionContent[6].title}</div>
          <div className="cnt-text">{sectionContent[6].textContent}</div>
          <ul className="check-list">
            {pointsForToken.map((point, index) => (
              <li key={index} className="check-item">
                <div className="marks">
                  <div className="point" />
                  <div className="mark-title">{point.title}</div>
                </div>

                <div className="shape">
                  <div className="stripe" />
                  <div className="shape-text">{point.text}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="Container" id="section3">
        <div className="aboutSection">
          <div
            className="img-section img-cont-aligm"
            style={{ backgroundImage: `url(${imgArray[2]})` }}
          ></div>
          <div className="about-textContent">
            <div className="about-title type1 title-text">
              {sectionContent[7].title}
            </div>
            <div className="about-text type1 cnt-text">
              {sectionContent[7].textContent}
            </div>
          </div>
        </div>

        <ul className="emplList">
          {emplList.map((employee, index) => (
            <li key={index}>
              <EmplCard
                name={employee.name}
                business={employee.business}
                text={employee.text}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="Container" id="section4">
        <div className="aboutSection">
          <div className="about-textContent flip">
            <div className="about-title type2 title-text">
              {sectionContent[8].title}
            </div>
            <div className="about-subtitle type2 sub-title">
              {sectionContent[8].sub_title}
            </div>
            <div className="about-text type2 cnt-text">
              {sectionContent[8].textContent}
            </div>
          </div>
          <div
            className="img-section img-cont-aligm "
            style={{ backgroundImage: `url(${imgArray[0]})` }}
          ></div>
        </div>

        <ul className="img-gallery pre-header">
          {imgArray.slice(1, 5).map((imgUrl, index) => (
            <li
              key={index}
              className="img-item img-cont-aligm"
              style={{ backgroundImage: `url(${imgUrl})` }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
