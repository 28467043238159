import React, { useState } from "react";
import "./EmplCard.scss";

const EmplCard = ({ name, business, text }) => {
  const [expanded, setExpanded] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});
  const toggleExpanded = () => {
    setExpanded(!expanded);
    setButtonStyle(expanded ? {} : {});
  };

  return (
    <div className="EmplCard">
      <div className="empl-content">
        <div className="emplName">{name}</div>
        <div className="emplBusiness">{business}</div>
        <div
          className="emplText"
          style={{ display: expanded ? "block" : "none" }}
        >
          {text}
        </div>
      </div>

      <div
        className={"empl-info-btn"}
        style={buttonStyle}
        onClick={toggleExpanded}
      >
        {expanded ? "Read less" : "Read more"}
      </div>
    </div>
  );
};

export default EmplCard;
