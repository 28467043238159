import img1 from "./assets/images/IMG_01.jpg";
import img2 from "./assets/images/IMG_02.jpg";
import img3 from "./assets/images/IMG_03.jpg";
import img4 from "./assets/images/IMG_04.jpg";
import img5 from "./assets/images/IMG_05.jpg";
import img6 from "./assets/images/IMG_06.jpg";

import ico1 from "./assets/icons/ICO_01.png"
import ico2 from "./assets/icons/ICO_02.png"
import ico3 from "./assets/icons/ICO_03.png"
import ico4 from "./assets/icons/ICO_04.png"
import ico5 from "./assets/icons/ICO_05.png"

const imgArray = [img1, img2, img3, img4, img5, img6];

const pointsForToken = [
  {
    title: <p>Support for Local Farmers</p>,
    text: (
      <p>
        Contribute directly to the livelihoods of Palestinian farmers, helping
        them overcome economic challenges.
      </p>
    ),
  },
  {
    title: <p>Sustainable Farming Practices</p>,
    text: (
      <p>
        Funds are reinvested into sustainable farming practices, ensuring
        environmental stewardship.
      </p>
    ),
  },
  {
    title: <p>Global Connectivity</p>,
    text: (
      <p>
        Join a global network of supporters connected to Palestinian
        agriculture.
      </p>
    ),
  },
  {
    title: <p>Transparency and Accountability</p>,
    text: (
      <p>
        Blockchain technology ensures transparency and accountability in tree
        ownership and management.
      </p>
    ),
  },
  {
    title: <p>Cultural and Artistic Integration</p>,
    text: (
      <p>
        Each token includes digital art by renowned artists, appealing to NFT
        and art enthusiasts.
      </p>
    ),
  },
  {
    title: <p>Share of the Produce</p>,
    text: (
      <p>
        Token holders get a share of the produce from their tree, connecting
        them to the agricultural process.
      </p>
    ),
  },
];

const sectionContent = [
  {
    title: <p>Holy Farm</p>,
    textContent: <p>Holy Farm Investing in a Greener Tomorrow.</p>,
  },

  {
    title: <p>About Us</p>,
    textContent: (
      <p>
        Holy Farm, founded by Palestinian entrepreneurs and agricultural
        leaders, aims to empower the local agricultural sector, focusing on the
        palm sector and Medjool dates. We are committed to ensuring equal
        opportunities for farmers, producers, investors, and workers. Our
        mission includes exporting products, investing in agro-processing,
        introducing new technologies, and opening new distribution channels to
        market Palestinian products globally.
      </p>
    ),
  },

  {
    title: <p>Our Vision</p>,
    textContent: (
      <p>
        At Holy Farm, we aspire to become the leading and most reliable
        marketing company for Palestinian agricultural and agro-processing
        products .
      </p>
    ),
  },
  {
    title: <p>Our Mission</p>,
    textContent: (
      <p>
        It all begins with cultivating the land, the art of nourishing the seed,
        and the values employed within every aspect of production. Fairness &
        Equality Quality & Timeliness Sustainability & Reliability Scalability
        Environmental Commitment & Respect
      </p>
    ),
  },
  {
    title: <p>Our Values</p>,
    textContent: (
      <p>
        It all begins with cultivating the land, the art of nourishing the seed,
        and the values employed within every aspect of production.
      </p>
    ),
  },

  {
    title: <p>Tree Token Initiative</p>,
    sub_title: <p>Holy Farm Investing in a Greener Tomorrow</p>,
    textContent: (
      <p>
        At Holy Farm, we are excited to partner with Funooni to bring you the
        innovative Tree Token Initiative, merging traditional agriculture and
        modern technology. Through this project, you can now support Palestinian
        agriculture and prominent levantine artists by owning and growing a palm
        tree accompanied by a unique NFT (Non-Fungible Token). Each token is
        linked to a specific palm tree, which is carefully nurtured and
        cultivated by our dedicated farmers. By leveraging blockchain
        technology, we ensure transparency, traceability, and authenticity in
        the ownership and management of each tree and its associated artwork.
        The Tree Token Initiative not only provides a new source of funding for
        our farmers but also fosters a global community of supporters who are
        directly connected to the land and its produce.
        <br />
        <br />
        Visit <b>Funooni.io</b> to learn more and become part of this
        transformative journey.
      </p>
    ),
  },
  {
    title: <p>Why should you take part in the Tree Token Initiative? </p>,
    textContent: (
      <p>
        The Tree Token initiative is more than just a digital innovation; it is
        a meaningful step towards sustainable agriculture and economic
        empowerment for Palestinian farmers.{" "}
      </p>
    ),
  },
  {
    title: <p>Our Team</p>,
    textContent: (
      <p>
        Our family promotes a sustainable green future that is safe and fair for
        all. We integrate our core values throughout each step of our work.{" "}
      </p>
    ),
  },
  {
    title: <p>We Take Pride in our Palestinian Farmers! </p>,
    sub_title: <p>We Take Pride in our Palestinian Farmers!</p>,
    textContent: (
      <p>
        Our community consists of small-family farmers & producers, women, and
        cooperatives; who have over 10 years of experience in farming and
        agro-processing industries. <br/><br/>We support our community in overcoming the
        threats that exist in the modern agriculture industry. Our team works
        towards developing strategic partnerships, sustainable technology
        solutions, and green tactics. Together with guaranteeing equal rights
        and opportunities for our community.
      </p>
    ),
  },
];

const valueSection = [
  {
    name: <p>Fairness & Equality</p>,
    icon: ico1,
  },
  {
    name: <p>Quality & Timeliness</p>,
    icon: ico2,
  },
  {
    name: <p>Sustainability & Reliability</p>,
    icon: ico3,
  },
  {
    name: <p>Scalability</p>,
    icon: ico4,
  },
  {
    name: <p>Environmental Commitment & Respect</p>,
    icon: ico5,
  },
];

const emplList = [
  {
    name: <p>Baha Sartwai</p>,
    business: <p>Founder and Chairman </p>,
    text: (
      <p>
        Mr. Baha is an agricultural pioneer and dedicated investor in the
        agricultural sector, leveraging 20 years of banking expertise. He has
        been actively involved in key agricultural projects, serving as a Member
        of the Palm Farmer Cooperative Association (PFCA) and advocating for
        agricultural financing, insurance, and agro micro-financing. Prior to
        this, he led financial investment strategies at Arab Islamic Bank and
        directed credit initiatives at Egyptian Land Bank.
      </p>
    ),
  },
  {
    name: <p>Mohammad Al Kawasmi </p>,
    business: <p>Co-Founder Managing Partner</p>,
    text: (
      <p>
        Eng. Mohammad is the President of the Palm Farmer Cooperative
        Association in Jericho and the Jordan Valley, Founder and Chairman of
        the Arab Farmers Company, and Founder and Chairman of the Board of
        Trustees at Al Qasimi Academy. His career highlights include
        establishing the first Palestinian-Greek joint company in 1992,
        alongside his pivotal role in founding Globalcom Telecommunications. He
        has also held influential positions such as Chairman of the
        Greek-Palestinian Company, Al-Safi Company for Administrative and
        Financial Systems. Demonstrating a commitment to societal advancement
        alongside his diverse investments in agriculture, education, health, and
        technology sectors in Palestine.
      </p>
    ),
  },
  {
    name: <p>Shadi El Khatib</p>,
    business: <p>Managing Partner</p>,
    text: (
      <p>
        Mr. Al-Khatib is the executive director of Aswaq since the beginning of
        2016. He has more than 15 years of senior working experience in capital
        market investments which covers a wide range of economic sectors such as
        commercial, Islamic banking, telecommunications and light and agro
        industries.
      </p>
    ),
  },
  {
    name: <p>Raed Taha </p>,
    business: <p>Managing Partner</p>,
    text: (
      <p>
        Raed is a senior professional with over 25 years of experience in the
        financial, ICT and Fintech sectors. He held senior positions with
        regional and world renown ICT companies such as Nour Smart Solutions and
        Nour Pay in (Saudi), Waseela For Telecom Consultations (Dubai), RedLine
        (Canada) as well as worked with financial institutions such as JP Morgan
        Chase Bank (Vancouver Canada) and the Egyptian Arab Land Bank and Arab
        Islamic Bank in Palestine.
      </p>
    ),
  },
];

export { sectionContent, imgArray, pointsForToken, valueSection, emplList };
