import React from 'react'
import "./SectionBox.scss"

const SectionBox = ({title,text,...attr}) => {
  return (
    <div className='SectionBox'>
      <div className="sectionTitle">{title}</div>
      <div className="sectionText">{text}</div>
    </div>
  )
}

export default SectionBox
