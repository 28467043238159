import React from "react";
import { Link } from "react-scroll";
import "./Navbar.scss";


const Navbar = () => {
  

  return (
    <div className="Navbar">
      <div className="Navbar-container">
        
        <div className="anchors">
          <ul>
            <li>
              <Link
                to="section1"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
                offset={-100}
              >
                ABOUT US
              </Link>
            </li>
            <li>
              <Link
                to="section2"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
                offset={-100}
              >
                Tree Token
              </Link>
            </li>
            <li>
              <Link
                to="section3"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
                offset={-100}
              >
                Our Team
              </Link>
            </li>
            <li>
              <Link
                to="section4"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
                offset={-100}
              >
                Meet our Growers
              </Link>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
